import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'app/shared/providers/AuthProvider';
import { useLocation, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import queryString from 'query-string';

// Components
import {
  U21Button,
  U21Section,
  U21Form,
  U21FormField,
  U21FormFieldTypes,
  U21Spacer,
  U21Themes,
  U21Typography,
} from 'app/shared/u21-ui/components';
import ToastMessages from 'app/shared/toasts/components/ToastMessages';
import LogoutModal from 'app/shared/components/LogoutModal';

// Selectors
import { selectAuthEmailSent } from 'app/modules/session/selectors';
import { selectThemeMode } from 'app/modules/theme/selectors';

// Thunks
import {
  initAuthEmailSent,
  sendAuthEmailThunk,
} from 'app/modules/session/sliceSession';

// Utils
import isEmailValid from 'app/shared/utils/isEmailValid';
import { getA11yClickProps } from 'app/shared/utils/a11y';
import unit21Logo from 'app/images/logos/unit21.png';
import unit21LogoDarkMode from 'app/images/logos/unit21-darkmode.png';
import loginInPattern from 'app/images/log-in-pattern.png';
import emailCloud from 'app/images/email-cloud.png';
import { IconMail, IconBrandGoogle } from '@u21/tabler-icons';

const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { authClient, setAuthClient, auth0 } = useAuth();
  const authEmailSent = useSelector(selectAuthEmailSent);
  const themeMode = useSelector(selectThemeMode);
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (authClient.provider === 'auth0') {
      authClient.authorize();
    }
  }, [authClient]);

  useEffect(() => {
    if (search) {
      const { logout }: { logout?: boolean } = queryString.parse(search);
      setShowLogoutModal(Boolean(logout));
    }
  }, [search]);

  const reEnterEmail = () => {
    dispatch(initAuthEmailSent());
  };

  const updateClientAuth0 = () => {
    auth0.connection = undefined;
    setAuthClient(auth0);
  };

  const dismissLogoutModal = () => {
    setShowLogoutModal(false);
    history.replace('/agent-login');
  };

  const renderU21Logo = () => {
    if (themeMode === U21Themes.DARK) {
      return <StyledImg src={unit21LogoDarkMode} alt="Unit21 Logo" />;
    }
    return <StyledImg src={unit21Logo} alt="Unit21 Logo" />;
  };
  return (
    <Container>
      <LogoutModal open={showLogoutModal} dismiss={dismissLogoutModal} />
      <ToastMessages />
      <StyledU21Section>
        <>
          {!authEmailSent ? (
            <StyledU21Spacer spacing={4}>
              {renderU21Logo()}
              <StyledU21Form
                onSubmit={(values: { email: string }) => {
                  setEmail(values.email);
                  dispatch(sendAuthEmailThunk({ email: values.email }));
                }}
              >
                <U21FormField<string>
                  fieldProps={{
                    clearable: false,
                    label: 'Your work email',
                  }}
                  name="email"
                  type={U21FormFieldTypes.TEXT}
                  validate={(value) =>
                    isEmailValid(value)
                      ? undefined
                      : 'Please enter a valid email.'
                  }
                />
                <ButtonContainer>
                  <U21Button
                    color="primary"
                    size="large"
                    startIcon={<IconMail size={24} />}
                    type="submit"
                    variant="contained"
                  >
                    Send me a magic link
                  </U21Button>
                </ButtonContainer>
              </StyledU21Form>
              <U21Button
                onClick={() => {
                  auth0.connection = 'google-oauth2';
                  setAuthClient(auth0);
                }}
                startIcon={<IconBrandGoogle size={24} />}
                size="large"
                color="primary"
              >
                Continue with Google
              </U21Button>
              <HiddenLink id="cypress-login" onClick={updateClientAuth0}>
                Continue with Auth0
              </HiddenLink>
              <StyledText>
                Or use SAML to login if you have set it up.
              </StyledText>
            </StyledU21Spacer>
          ) : (
            <StyledU21Spacer spacing="16px">
              <img alt="Email" src={emailCloud} />
              <StyledU21Typography variant="h4">
                Check your email
              </StyledU21Typography>
              <StyledU21Typography variant="body2">
                If your email address <Bold>{email}</Bold> is registered with
                Unit21, you&apos;ll receive a magic link to login!
              </StyledU21Typography>
              <StyledU21Typography variant="caption" color="text.secondary">
                Can&apos;t see the email? Please check your spam folder.
                <br />
                Wrong Email?{' '}
                <BackButton {...getA11yClickProps(reEnterEmail)}>
                  Please re-enter your address.
                </BackButton>
              </StyledU21Typography>
            </StyledU21Spacer>
          )}
        </>
      </StyledU21Section>
    </Container>
  );
};

export default Login;

const Container = styled('div')`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${loginInPattern});
  background-position: center;
  background-size: contain;
`;

const HiddenLink = styled('a')`
  display: none;
`;

const StyledU21Section = styled(U21Section)`
  max-width: 575px;
  width: 100%;
  border-radius: 16px;
`;

const StyledU21Spacer = styled(U21Spacer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 460px;
  margin: 0 auto;
  padding-top: 30px;
`;

const StyledImg = styled('img')`
  max-width: 222px;
  width: 100%;
`;

const StyledU21Form = styled(U21Form)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const StyledText = styled('p')`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

const StyledU21Typography = styled(U21Typography)`
  width: 100%;
  justify-content: center;
  text-align: center;
`;

const Bold = styled('span')`
  font-weight: 700;
`;

const BackButton = styled('span')`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    cursor: pointer;
  `}
`;
