import { call, put, select, takeLatest } from 'redux-saga/effects';

import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import {
  SarActionTypes,
  CreateSarPayload,
  ReportType,
} from 'app/modules/fincenSar/models';

// Selectors
import { selectNewSarContent } from 'app/modules/fincenSar/selectors';

// Actions
import {
  createSar,
  createNewSar as createNewSarAction,
} from 'app/modules/fincenSar/actions';

const rootAction = SarActionTypes.CREATE_NEW_SAR;
function* createNewSar({ payload }: ReturnType<typeof createNewSarAction>) {
  const config = {
    rootAction,
    request: call(() => {}),
    success: function* onSuccess() {
      const content = yield select(
        selectNewSarContent(payload.name, payload.case_id),
      );

      const payloadWithContent: CreateSarPayload = {
        ...payload,
        content,
        report_type: ReportType.FINCEN_SAR,
      };

      yield put(createSar(payloadWithContent));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchCreateNewSar() {
  yield takeLatest(rootAction, createNewSar);
}
