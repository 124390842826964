import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { CASE_QUERY_KEYS } from 'app/modules/cases/queries/keys';
import {
  InvestigationsCaseResponse,
  InvestigationsPayload,
} from 'app/modules/investigations/types';
import { useSelector } from 'react-redux';
import { selectHasReadCasesPermissions } from 'app/modules/session/selectors';

export const useGetCase = (id: number) => {
  const hasReadCasesPermission = useSelector(selectHasReadCasesPermissions);
  return useQuery({
    enabled: hasReadCasesPermission,
    queryKey: CASE_QUERY_KEYS.getCase(id),
    queryFn: () =>
      post<InvestigationsCaseResponse<false>>('/investigations/retrieve/case', {
        short: false,
        object_type: 'CASE',
        object_ids: [id],
      } satisfies InvestigationsPayload),
  });
};
